<script>
import FormPage from '../base/FormPage.vue'
export default {
  extends: FormPage,
  methods: {
    async submit () {
      const loading = this.$buefy.loading.open()
      try {
        const data = this.getData('form-field')
        await this.$portalApi.post('/auth/reset/' + this.user.Oid, data)
        this.$router.back()
      } catch (err) {
        this.handleSubmitError(err)
      } finally {
        loading.close()
      }
    }
  }
}
</script>

<style>

</style>
